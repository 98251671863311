<template>
  <transition name="fade">
    <div class="alert-box-wrapper" v-if="sendshow">
      <!-- 提示领券 -->
      <div class="alert-box" v-if="showType == 1">
        <div class="topimg"><img src="../../assets/singin-coupon-top.png" alt="" /></div>
        <div class="top-title">注册会员有礼</div>
        <div class="top-subhead">授权手机号注册，享更多活动优惠</div>
        <div class="coupon-list">
          <div class="coupon-item" v-for="item in dataList">
            <div>{{ item.typeToString }}</div>
            <div>{{ item.valueToString }}</div>
          </div>
        </div>
        <van-button round block class="btn" @click="showType = 2">一键授权注册</van-button>
        <div class="close" @click="handleClose"><van-icon name="cross" /></div>
      </div>
      <!-- 注册 -->
      <div class="singin" v-else-if="showType == 2">
        <div style="padding: 0 15px">
          <div class="close" @click="handleClose"><van-icon name="cross" /></div>
          <div class="singin-title">注册平台会员</div>
          <div class="getcode">
            <van-field
              v-model="singinForm.phone"
              type="tel"
              label="手机号"
              placeholder="请输入您的手机号"
              style="background-color: #f8f8f8"
              required
            />
            <van-field
              v-model="singinForm.code"
              center
              clearable
              label="验证码"
              placeholder="请输入验证码"
              style="background-color: #f8f8f8"
              required
            >
              <template #button>
                <van-button class="getcodebtn" size="small" type="info" @click="handleSendcode">点击获取</van-button>
              </template>
            </van-field>
          </div>
        </div>
        <div class="submit-btn">
          <van-button type="default" block class="cancle" @click="handleClose">取消</van-button>
          <van-button type="default" block class="sure" @click="submit">确认</van-button>
        </div>
      </div>
      <!-- 抢到券 -->
      <div class="acquire-coupon" v-else-if="showType == 3">
        <div class="topimg"><img src="../../assets/getcpupon-top.png" /></div>
        <div class="gettitle">喜提新会员啦~</div>
        <div class="coupon-list-box">
          <div class="coupon-item" v-for="item in successList">
            <div class="left">
              <div>{{ item.type == 2 ? item.typeToString + `(${item.valueToString})` : item.valueToString }}</div>
              <div>{{ item.validityTimeToString }}</div>
            </div>
            <div class="right">{{ item.typeToString }}</div>
          </div>
        </div>
        <div class="ground-floor"></div>
        <div class="first-floor">
          <div class="getbtn" @click="handleClose">开心收下</div>
          <div class="check">可在“中智停车”小程序 > 优惠券中查看</div>
        </div>
        <div class="close" @click="handleClose"><van-icon name="cross" /></div>
      </div>
      <!-- 未抢到 -->
      <div class="noget" v-else-if="showType == 4">
        <div class="sad"><img src="../../assets/sad.png" /></div>
        <div class="sadtext">很遗憾</div>
        <div class="sadtext" style="font-size: 15px; font-weight: 400">手慢无，停车券被秒光了！</div>
        <van-button round type="info" block class="close" @click="handleClose">关闭窗口</van-button>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "signinCouponAlert",
  props: {
    sendshow: {
      type: Boolean,
      require: true,
    },
  },
  data() {
    return {
      show: false,
      singinForm: {
        phone: null,
        code: null,
      },
      showType: 1,
      id: null,
      dataList: [],
      successList: [],
      domain: process.env.VUE_APP_DOMAIN,
    };
  },
  mounted() {
    this.getCouponList();
  },
  methods: {
    submit() {
      let appId = localStorage.getItem("appId");
      let wxopenId = localStorage.getItem("openId");
      let alipayuserId = localStorage.getItem("alipayuserId");
      let openId = wxopenId ? wxopenId : alipayuserId;
      this.$axios
        .post(`${this.domain}/api/opercenter/registerMemberForH5`, {
          appId,
          openId,
          ...this.singinForm,
        })
        .then((res) => {
          if (res.data.code === 200) {
            this.handleSignin();
            this.$axios
              .post(`${this.domain}/api/opercenter/queryActivityOfH5FeedBack`, {
                appId,
                openId,
              })
              .then((res) => {
                if (res.data.data !== null && res.data.data.bzEquitiesGoodsList.length) {
                  this.successList = res.data.data.bzEquitiesGoodsList;
                  this.showType = 3;
                  this.$toast.success("注册成功");
                } else {
                  this.showType = 4;
                }
              });
          } else {
            this.$toast.fail(`${res.data.msg}`);
          }
        })
        .catch(() => {
          this.$toast.fail("注册失败");
        });
    },
    // 点击获取
    handleSendcode() {
      let appId = localStorage.getItem("appId");
      let { phone } = this.singinForm;
      let wxopenId = localStorage.getItem("openId");
      let alipayuserId = localStorage.getItem("alipayuserId");
      this.$axios
        .post(`${this.domain}/api/opercenter/sendVerificationCode`, {
          appId,
          phone,
          openId: wxopenId ? wxopenId : alipayuserId,
        })
        .then((res) => {
          if (res.data.code === 200) {
            this.$toast.success("发送成功,请注意查看短信");
          } else {
            this.$toast.fail(`${res.data.msg}`);
          }
        })
        .catch(() => {
          this.$toast.fail("发送失败");
        });
    },
    // 一键授权注册
    handleSignin() {
      let appId = localStorage.getItem("appId");
      let wxopenId = localStorage.getItem("openId");
      let alipayuserId = localStorage.getItem("alipayuserId");
      let openId = wxopenId ? wxopenId : alipayuserId;
      let { id } = this;
      this.$axios.post(`${this.domain}/api/opercenter/disposeFocusOfficial`, {
        appId,
        openId,
        id,
      });
    },
    // 列表
    getCouponList() {
      let appId = localStorage.getItem("appId");
      let openId = localStorage.getItem("openId");
      let alipayuserId = localStorage.getItem("alipayuserId");
      this.$axios
        .post(`${this.domain}/api/opercenter/queryBzActivityOfParkPay`, {
          appId,
          openId: openId ? openId : alipayuserId,
        })
        .then((res) => {
          if (res.data.data.filter((item) => item.type == 1).length) {
            this.dataList = res.data.data.filter((item) => item.type == 1)[0].bzEquitiesGoodsList;
            this.id = res.data.data.filter((item) => item.type == 1)[0].id;
          }
        });
    },
    handleClose() {
      this.$emit("destroyed");
    },
    destroyVm() {
      // 销毁
      setTimeout(() => {
        this.$destroy(true);
        this.$el && this.$el.parentNode.removeChild(this.$el);
      }, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.alert-box-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);

  .alert-box {
    position: relative;
    box-sizing: border-box;
    padding: 20px 25px;
    width: 280px;
    background: linear-gradient(119.62deg, rgb(255, 229, 202) 9.493%, rgb(255, 243, 229) 93.197%);
    box-shadow: 0px 2px 20px 0px rgba(15, 54, 125, 0.08);
    border-radius: 20px;

    .topimg {
      position: absolute;
      top: -56px;
      left: 50%;
      transform: translateX(-50%);
      width: 308px;

      img {
        width: 100%;
      }
    }

    .top-title {
      display: flex;
      justify-content: center;
      font-size: 25px;
      color: #b45227;
      font-weight: 600;
    }

    .top-subhead {
      margin-top: 10px;
      display: flex;
      justify-content: center;
      font-size: 15px;
      color: #b45227;
    }

    .coupon-list {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin-top: 25px;
      width: 100%;

      .coupon-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 110px;
        height: 60px;
        background: url("../../assets/singin-couponBG.png") no-repeat;
        background-size: 100% 100%;

        :nth-child(1) {
          font-size: 15px;
          color: #fefbca;
        }

        :nth-child(2) {
          font-size: 13px;
          color: #ffffff;
        }
      }
    }

    .btn {
      margin-top: 20px;
      color: #fff;
      font-size: 15px;
      border: none;
      background: linear-gradient(180deg, rgb(255, 167, 99), rgb(250, 114, 12) 100%);
    }

    .close {
      position: absolute;
      bottom: -60px;
      left: 50%;
      transform: translateX(-50%);
      width: 30px;
      height: 30px;
      line-height: 30px;
      border: 1.5px solid rgb(255, 255, 255);
      border-radius: 50%;
      color: #fff;
      text-align: center;
      font-size: 18px;
    }
  }

  .singin {
    width: 300px;
    border-radius: 5px;
    background: rgb(255, 255, 255);

    .close {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
      color: #b1b1b1;
      font-size: 16px;
    }

    .singin-title {
      display: flex;
      justify-content: center;
      font-size: 20px;
      color: #333333;
      font-weight: 600;
    }

    .getcode {
      margin-top: 15px;
      background-color: #f8f8f8;

      .getcodebtn {
        background: rgba(35, 112, 255, 0.1);
        border-radius: 5px;
        color: #2370ff;
        border: none;
      }
    }

    .submit-btn {
      margin-top: 15px;
      display: flex;

      .cancle {
        border-right: none;
      }

      .sure {
        color: #2370ff;
      }
    }
  }

  .acquire-coupon {
    box-sizing: border-box;
    position: relative;
    padding: 10px 8px;
    width: 286px;
    background: linear-gradient(120.05deg, rgb(255, 229, 202) 11.891%, rgb(255, 243, 229) 90.948%);
    border-radius: 20px;

    .topimg {
      position: absolute;
      top: -65px;
      left: 50%;
      transform: translateX(-50%);
      width: 217px;
      height: 90px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .gettitle {
      margin-top: 26px;
      display: flex;
      justify-content: center;
      font-size: 25px;
      color: #b45227;
      font-weight: bold;
    }

    .coupon-list-box {
      margin-top: 10px;
      margin-bottom: 85px;
      box-sizing: border-box;
      padding: 10px;
      background-color: rgb(255, 199, 168);
      border-radius: 20px;
      height: 280px;
      min-height: 280px;
      overflow-y: auto;

      div:last-child {
        margin-bottom: 0;
      }

      .coupon-item {
        display: flex;
        margin-bottom: 10px;
        width: 100%;
        height: 60px;
        background: url("../../assets/getcoupon-typlistbg.png") no-repeat;
        background-size: 100% 100%;

        .left {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 68%;
          color: #b45227;

          :nth-child(1) {
            font-size: 18px;
            font-weight: 550;
          }
          :nth-child(2) {
            font-size: 13px;
          }
        }

        .right {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 32%;
          font-size: 18px;
          background-image: linear-gradient(180deg, rgb(254, 174, 94), rgb(250, 109, 45));
          -webkit-background-clip: text;
          color: transparent;
          font-weight: 550;
        }
      }
    }

    .ground-floor {
      z-index: -1;
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
      width: 300px;
      height: 220px;
      background: rgb(254, 160, 93);
      border-radius: 20px;
    }

    .first-floor {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
      width: 300px;
      height: 131px;
      background: url("../../assets/getcoupon-bottom.png") no-repeat;
      background-size: 100% 100%;

      .getbtn {
        margin-top: 40px;
        width: 150px;
        height: 44px;
        line-height: 44px;
        text-align: center;
        color: #8a3a16;
        font-size: 15px;
        background: url("../../assets/getcpupon-btn.png") no-repeat;
        background-size: 100% 100%;
      }

      .check {
        margin-top: 10px;
        color: #fefbca;
      }
    }

    .close {
      position: absolute;
      bottom: -60px;
      left: 50%;
      transform: translateX(-50%);
      width: 30px;
      height: 30px;
      line-height: 30px;
      border: 1.5px solid rgb(255, 255, 255);
      border-radius: 50%;
      color: #fff;
      text-align: center;
      font-size: 18px;
    }
  }

  .noget {
    position: relative;
    box-sizing: border-box;
    padding: 20px;
    width: 280px;
    background: linear-gradient(120.05deg, rgb(255, 229, 202) 4.502%, rgb(255, 243, 229) 97.877%);
    box-shadow: 0px 2px 20px 0px rgba(15, 54, 125, 0.08);
    border-radius: 20px;

    .sad {
      position: absolute;
      top: -30px;
      left: 50%;
      transform: translateX(-50%);
      width: 60px;
      height: 60px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .sadtext {
      margin-top: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #b45227;
      font-size: 20px;
      font-weight: 550;
    }

    .close {
      margin-top: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(180deg, rgb(255, 167, 99), rgb(250, 114, 12) 100%);
      color: #fff;
      border-radius: 22px;
      border: none;
    }
  }
}
</style>
